import React from 'react'

const InputText = ({type, label, name, placeholder, onChange, value}) => {

  const inputStyle = {
    border : '2px solid #612bc3',
    borderRadius: '15px',
    padding : '12px 20px',
    margin : '8px 0',
    width : '80%',
    justifyContent: 'center',
    fontSize: '17px',
    color: '#000',
    backgroundColor: '#D4D4D4'
  }
  const divInputStyle = {
    textAlign: 'left',
    padding : '12px',
  }
  const labelStyle = {
    fontWeight: 'bold',
    fontSize: '20px',
    marginLeft:'8px'
  }

  return (
    <div style={divInputStyle}>
      <label htmlFor={name} style={labelStyle}>{label} : </label>
      <input type={type} 
             placeholder={placeholder} 
             name={name} 
             style={inputStyle}
             onChange={(e)=>onChange(e.target.value)}
             value={value}
             required
      />
    </div>
    )
}

export default InputText