import React, { useEffect, useRef, useState } from 'react'
import ChangeLanguage from './ChangeLanguage';
import DarkLightMode from './DarkLightMode';
import Logout from './Logout';
import ProfileButton from './ProfileButton';

const BurgerMenu = ({auth, logout}) => {
    const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
    const menuRef = useRef(); // Référence pour le menu burger
    const buttonBurgerMenuRef = useRef(); // Référence pour le menu burger

    const handleClickOutside = (event, menuburger = false) => {
        if(menuburger){
            setIsOpen(!isOpen);
            return;
        }else if (menuRef.current && !menuRef.current.contains(event.target) 
            && !buttonBurgerMenuRef.current.contains(event.target)
            && window.innerWidth <= 768
            ) {
          setIsOpen(false);
        }
      };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        };
    
        window.addEventListener('resize', handleResize);
        
          document.addEventListener('mousedown', handleClickOutside);
        
          return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
          };
        }, []); 

        const toggleMenu = (event) => {
            event.preventDefault();
            handleClickOutside(event, true);
        };

  return (
    <div>
        <button id='buttonBurgerMenu' ref={buttonBurgerMenuRef} onClick={toggleMenu}>☰</button>
        {isOpen &&
            <div id='burger-menu-content' ref={menuRef}>
                <ul>
                    <li>
                        <ChangeLanguage />
                    </li>
                    <li>
                        <DarkLightMode />
                    </li>
                    <li>
                        <ProfileButton auth={auth}/>
                    </li>
                    <li>
                        <Logout auth={auth} logout={logout}/>
                    </li>
                </ul>
            </div>
        }
    </div>
  )
}

export default BurgerMenu