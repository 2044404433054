import React from 'react'
import { useRouteError } from "react-router-dom";
import SkeletonPage from './SkeletonPage';
import { useTranslation } from "react-i18next";

const ErrorPage = () => {
    const error = useRouteError();
    console.log("Something wrong happened: ". error);
    const { t, i18n } = useTranslation();

    const jsx = 
        <div id="error-page">
        <h1>{t('title_error')} !</h1>
        <p>{t('text_error')}.</p>

        <h3>{error.status}</h3>
        <p>
            <i>{error.statusText || error.message}</i>
        </p>
    </div>  
  return (
   <SkeletonPage content={jsx} />
  )
}

export default ErrorPage