import React, {useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaCheck, FaPen, FaTimes, FaTrash } from "react-icons/fa";
import { ThreeDots } from 'react-loader-spinner';
import { Tooltip } from 'react-tooltip'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import SubGoalsTable from './SubGoalsTable';
import InputLight from './Input/InputLight';

const GoalCard = ({
    goal,
    tempGoal,
    editingId,
    setTempGoal,
    startEditing,
    saveEditing,
    cancelEditing,
    deleteGoal,
    handleMouseOver,
    handleMouseOut,
    updatingId,
    handleCardClick,
    getAdvancement,
    handleCloseModal,
    origin = null
}) => {
    const { t, i18n } = useTranslation();

    const [startDate, setStartDate] = useState(new Date(goal.startDate));
    const [endDate, setEndDate] = useState(new Date(goal.endDate));

    const advancementInfos = getAdvancement(goal.startDate, goal.endDate, goal.keyNumber, goal.advancement);

    const styles = {
        cardContainer: {
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0 2px 4px rgba(0,0,0,0.4)',
            width: '300px',
            height: '300px',
            margin: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            transition:'box-shadow 0.3s ease',
        },
        header: {
            backgroundColor: '#612bc3',
            padding: '12px 16px',
            borderBottom: '1px solid #e0e0e0',
            color: "#000",
        },
        content: {
            padding: '12px 16px',
            minHeight: '80%',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column',
        },
        footer: {
            display: 'flex',
            justifyContent: 'space-around',
            padding: '12px 16px',
            borderTop: '1px solid #e0e0e0'
        },
        button: {
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            cursor: 'pointer'
        },
        titles: {
            fontWeight: 'bold',
            margin: '9px'
        },
        datePicker: {
            border: '1px solid #612bc3',
            borderRadius: '15px',
            padding : '9px 12px',
            margin : '5px 0',
            justifyContent: 'center',
            fontSize: '14px',
            color: '#612bc3',
            backgroundColor: '#D4D4D4',
        }
    };
    let height = '300px'
    let className = ""
    if(origin === "modal"){
        styles.cardContainer.width = '100%';
        styles.cardContainer.height = '100%';
        styles.cardContainer.overflowY = 'auto';
        styles.cardContainer.margin = '0';
        height = "800px"
    } else {
        styles.content.cursor = 'pointer';
        styles.header.cursor = 'pointer';
        className = "cardGoals"
    }

  return (
    
     <div style={styles.cardContainer} className={className} key={goal.id} >
            {updatingId === goal.id ? (
                <ThreeDots
                    height={height} 
                    width="100%" 
                    radius="9"
                    color="#612bc3" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                    />
            ) : (
    <> 
    <div onClick={() => handleCardClick(goal)}>
    <div style={styles.header}>
        {editingId === goal.id ? (
            // <input value={tempGoal.name} onChange={(e) => setTempGoal({...tempGoal, name: e.target.value})} />
            <InputLight value={tempGoal.name} onChange={(e) => setTempGoal({...tempGoal, name: e.target.value})} />
            ) : (
                <h3>{goal.name}</h3>
            )}   
     </div>
    <div style={styles.content}>
        {/* Key Nbr */}
        <p> 
            {editingId === goal.id ? (
                <InputLight value={tempGoal.keyNumber} onChange={(e) => setTempGoal({...tempGoal, keyNumber: e.target.value})} label={t('table_goals_nbr_key')} name={'key-nbr-input'}/>
                ) : (
                <>
                    <span style={styles.titles}>{t('table_goals_nbr_key')} : </span>
                    {goal.keyNumber}
                </>
            )}
        </p>
        {/* Avancement */}
        <p> 
            {editingId === goal.id ? (
                <InputLight value={tempGoal.advancement} onChange={(e) => setTempGoal({...tempGoal, advancement: e.target.value})} label={t('table_goals_advancement')} />
                ) : (
                <>
                    <span style={styles.titles}>{t('table_goals_advancement')} : </span>
                    {goal.advancement}
                </>
            )}
        </p>
        {/* Avancement Pourcentage */}
        <p> <span style={styles.titles}>{t('card_goals_advancement_percentage')} : </span> 
            <span style={{color: advancementInfos.color}}>
            {advancementInfos.advancementPercentageReal} % <br/>
            {t(advancementInfos.text)}
        </span>
        </p>
        <p> <span style={styles.titles}>{t('card_goals_last_update')} : </span> 
                {new Date(goal.updatedAt).toLocaleString(
                    i18n.language === 'fr' ? 'fr-FR' : 'en-US',
                    {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }
                )}
         </p>
         {origin === "modal" && 
         <>
         {/* Date de début */}
         <p className='datepicker-p'> 
            <span style={styles.titles}>{t('card_goal_date_start')} : </span>
            {editingId === goal.id ? (
                <DatePicker 
                selected={startDate} 
                openToDate={new Date(goal.startDate)}
                onChange={(date) => {setTempGoal({
                    ...tempGoal, startDate: date
                })
                setStartDate(date)
            }} />
                ) : (
                    new Date(goal.startDate).toLocaleString(
                        i18n.language === 'fr' ? 'fr-FR' : 'en-US',
                        {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        }
                    )            )}
        </p>
        {/* Date de fin */}
        <p className='datepicker-p'> 
            <span style={styles.titles}>{t('card_goal_date_end')} : </span>
            {editingId === goal.id ? (
                <DatePicker selected={endDate}
                value={new Date(goal.endDate)}
                onChange={(date) => {
                    setTempGoal({
                    ...tempGoal, endDate: date
                })
                setEndDate(date)
            }
            } />
                    ) : (
                    new Date(goal.endDate).toLocaleString(
                        i18n.language === 'fr' ? 'fr-FR' : 'en-US',
                        {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        }
                    )            )}
        </p>
        
        {/* SubGoals */}
        <p>
            <span style={styles.titles}>{t('card_goal_subgoals')} : </span>
                <SubGoalsTable goal={goal} />
                {/* Si pas de subGoals on pourra faire un lien  */}
        </p>

         </>}

         
    </div>
    </div>       

    <div style={styles.footer}>
    {editingId === goal.id ? (
                    <>
                        <FaCheck onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                style={{color:'green', fontSize:'20px', margin:'5px'}}
                                onClick={() => saveEditing(goal.id)}
                                className="save-goal-button"
                                />
                        <FaTimes onMouseOver={handleMouseOver}
                                onMouseOut={handleMouseOut}
                                style={{color:'red', fontSize:'20px', margin:'5px'}}
                                onClick={cancelEditing}
                                className="cancel-goal-button" 
                                />
                        <Tooltip anchorSelect=".save-goal-button" direction="top"> {t('save_button')} </Tooltip>
                        <Tooltip anchorSelect=".cancel-goal-button" direction="top"> {t('cancel_button')} </Tooltip>
                    </>
                ) : (

                    <>
                        <FaPen onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                            onClick={() => startEditing(goal)} 
                            style={{color:'#FF7F50', fontSize:'20px', margin:'5px'}}
                            className="edit-goal-button"
                            />
                    {origin !== "modal" && updatingId === null?
                        <FaTrash onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        onClick={() => deleteGoal(goal.id)} 
                        style={{color:'red', fontSize:'20px',margin:'5px'}}
                        className="delete-goal-button"
                        />
                     : <FaTimes onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        onClick={handleCloseModal}
                        style={{color:'red', fontSize:'20px',margin:'5px'}}
                        className="cancel-goal-button"
                        />            
                     }
                        <Tooltip anchorSelect=".cancel-goal-button" direction="top"> {t('cancel_button')} </Tooltip>
                        <Tooltip anchorSelect=".delete-goal-button" direction="top"> {t('delete_button')} </Tooltip>
                        <Tooltip anchorSelect=".edit-goal-button" direction="top"> {t('edit_button')} </Tooltip>
                    </>
     )}
    </div>
    </>
    )}  
</div>
  )
}

export default GoalCard