import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
import SkeletonPage from './SkeletonPage';
import { useTranslation } from 'react-i18next';

const WaitingPage = () => {
    const { t, i18n } = useTranslation();

    const LoaderStyle = {
        textAlign : 'center',
        minHeight : '50vh',
        paddingTop: '20vh'
      }

    const jsx = 
        <div style={LoaderStyle}>
            <RotatingLines
                strokeColor="#612bc3"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
                />
            <h3>
            {t('waiting_keyword')} ...
            </h3>
    </div> 
      
  return <> <SkeletonPage content={jsx} />  </>
  
}

export default WaitingPage