import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";
import SkeletonPage from './SkeletonPage';
import ChoiceHourDayCron from '../components/Profile/ChoiceHourDayCron';
import AuthContext from '../Context/AuthContext';

const Profile = () => {
    const { t, i18n } = useTranslation();
    const { auth, login, logout } = useContext(AuthContext);
    const jsx = 
    <>
        <h1>{t('profile_page_title')}</h1>
        <ChoiceHourDayCron auth={auth} />
    </>
      
  return (
    <>
        <SkeletonPage content={jsx} />
    </>
  )
}

export default Profile