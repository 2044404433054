import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";
import SkeletonPage from './SkeletonPage';
import AuthContext from '../Context/AuthContext';
import GoalCardView from '../components/GoalCardView';

const Goals = () => {
    const { t, i18n } = useTranslation();
    const { auth, login, logout } = useContext(AuthContext);


    const jsx =  
    <>
      <h3>{t('goals_title')}</h3>
      <GoalCardView  auth={auth} />
    </>
  return <SkeletonPage content={jsx} />
}

export default Goals