import React, { useEffect, useState } from 'react'
import i18n from '../i18n';

const ChangeLanguage = () => {
  const initialLanguage = ['en', 'fr'].includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0] : 'en';
  const [language, setLanguage] = useState(initialLanguage);
  
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value
    i18n.changeLanguage(languageValue);
    setLanguage(languageValue);
  }
  return (
      <select className="buttonHeaderFooter" value={language} onChange={changeLanguageHandler}>
        <option value="en" >English</option>
        <option value="fr">Français</option>
      </select>
  )
}

export default ChangeLanguage