import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

const useGoals = (auth, t) => {
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempGoal, setTempGoal] = useState({name: '', keyNumber: '', advancement: '', startDate: '', endDate: '', updatedAt: ''});
  const [editingId, setEditingId] = useState(null);
  const [updatingId, setUpdatingId] = useState(null);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  

  const apiUrl = process.env.REACT_APP_API_URL;
  const notify = (text, bgColor ,icon) => toast(text, {
      duration: 4000,
      position: 'top-center',
      style: {
          backgroundColor: bgColor
      },
      // Custom Icon
      icon: icon,
      // Change colors of success/error/loading icon
      iconTheme: {
        primary: '#000',
        secondary: '#fff',
      },
      // Aria
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });


  const getAdvancement = (startDate, endDate, keyNumber, nbAdvancement) => {
    const now = new Date();
    const daysPassed = Math.floor((now - new Date(startDate)) / (1000 * 60 * 60 * 24));
    const totalDay = Math.floor((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
    const advancementPercentageOfDay = Math.round(daysPassed / totalDay * 100);
    if(nbAdvancement <= 0) {
        nbAdvancement = 0;
    }
    const advancementPercentageReal = Math.round(nbAdvancement / keyNumber * 100);

    let text = '';
    let color = '';
    if(nbAdvancement == 0){
        text = 'not_started';
        color = 'grey';
    } else if(advancementPercentageReal > 100){
        text = 'exceeds';
        color = '#00cc66';
    } else if (advancementPercentageReal == 100){
        text = 'validate';
        color = '#00cc66';
    } else if (advancementPercentageReal >  advancementPercentageOfDay+10 ){
        text = 'in_advance';
        color = 'green';
    } else if (advancementPercentageReal <  advancementPercentageOfDay-10 ){
        text = 'late';
        color = 'red';
    } else {
        text = 'in_phase';
        color = '#87CEEB';
    }

    return {text: text,
            color: color,
            advancementPercentageReal: advancementPercentageReal,
            advancementPercentageOfDay: advancementPercentageOfDay
            };
  }

  useEffect(()=>{
      const fetchGoals = async () => {
          try{
              setLoading(true);
              const response = await axios.get(`${apiUrl}/users/${auth.userId}/goals`,
              {
                  headers: {
                      accept: 'application/ld+json',
                      Authorization: 'Bearer '+auth.token
                  }
              }
              );
              const goalsFromApi = response.data['hydra:member'];
              setGoals(goalsFromApi);
              setLoading(false);
          }catch(error){
              console.log("Something wrong happened: ". error);
              setLoading(false);
          }
          if(location.state && location.state.notifSuccess){
              notify(t('notif_success_create_goal'), '#7CFC00', ':D');
              location.state.notifSuccess = false;
          }

      }
      fetchGoals();
  }, [])

  //Edition des obj
  const startEditing = (goal) => {
    if(!isModalOpen){
        setIsModalOpen(true);
        setSelectedCard(goal);
    }
      setEditingId(goal.id);
      setTempGoal({
        name: goal.name,
        keyNumber: goal.keyNumber,
        advancement: goal.advancement,
        startDate: goal.startDate,
        endDate: goal.endDate
      })
  }
  const saveEditing = async (goalId) => {
      setUpdatingId(goalId);
      let newGoal = {
        name: tempGoal.name,
        keyNumber : parseInt(tempGoal.keyNumber),
        advancement: parseInt(tempGoal.advancement),
        startDate: tempGoal.startDate,
        endDate: tempGoal.endDate,
        updatedAt: new Date()
      }
      if(isModalOpen){
        setIsModalOpen(false);
        setSelectedCard(null);
    }
      try{
          const response = await axios.patch(`${apiUrl}/goals/${goalId}`, newGoal, 
              {
                  headers: {
                      "Authorization": 'Bearer '+auth.token,
                      "Content-Type": 'application/merge-patch+json'
                   }
              });
          notify(t('notif_success_update_goal'), '#7CFC00', ':D');
          if(response.status == 200){
              const updateGoalIndex = goals.findIndex(goal => goal.id === response.data.id);
              const updateGoals = [...goals];
              updateGoals[updateGoalIndex] = {...updateGoals[updateGoalIndex], ...newGoal};
              setGoals(updateGoals);
          }
        }catch(error){
          toast.error('Une erreur est survenue');
      }
      setEditingId(null);
      setUpdatingId(null);
      setTempGoal({name: '', keyNumber: '', advancement: '', startDate: '', endDate: '' });
      //Si la modale est ouverte on la ferme
        if(isModalOpen){
            setIsModalOpen(false);
            setSelectedCard(null);
        }
  }

  const cancelEditing = () => {
      setEditingId(null);
      setTempGoal({name: '', keyNumber: '', advancement: '', startDate: '', endDate: ''});
  }

  const deleteGoal = async (goalId) => {
      setUpdatingId(goalId);
      try{
          const response = await axios.delete(`${apiUrl}/goals/${goalId}`, 
              {
                  headers: {
                      "Authorization": 'Bearer '+auth.token,
                      "Content-Type": ' application/ld+json'
                   }
              });
          notify(t('notif_success_delete_goal'), '#7CFC00', ':D');
          if(response.status == 204){
              const deleteGoalIndex = goals.findIndex(goal => goal.id === response.data.id);
              const updateGoals = [...goals];
              updateGoals.splice(deleteGoalIndex, 1);
              setGoals(updateGoals);
          }
        }catch(error){
          toast.error(t('notif_error'));
      }
      setUpdatingId(null);
  }

    const handleMouseOver = (e) => {
        e.target.style.cursor = 'pointer';
    };

    const handleMouseOut = (e) => {
        e.target.style.cursor = 'default';
    };

    const handleCardClick = (cardDetails) => {
        setSelectedCard(cardDetails);
        if(editingId !== cardDetails.id){
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCard(null);
        cancelEditing();
    };
      
      

  return {
      goals,
      loading,
      tempGoal,
      editingId,
      updatingId,
      setTempGoal,
      startEditing,
      saveEditing,
      cancelEditing,
      deleteGoal,
      handleMouseOver,
      handleMouseOut,
      handleCardClick,
      handleCloseModal,
      isModalOpen,
      selectedCard,
      getAdvancement
  }
}

export default useGoals