import React, { useState } from 'react'
import AuthContext from '../../src/Context/AuthContext';

const AuthProvider = (props) => {
    const [auth, setAuth] = useState({token:null, userId:null});

    const login = (token, userId) => setAuth({token:token, userId:userId});
    const logout = () => {
      setAuth({token:null, userId:null})
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    }

  return (
    <AuthContext.Provider value={{auth, login, logout}}>
        {props.children}
    </AuthContext.Provider>
  )
}
export default AuthProvider