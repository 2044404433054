import React, { useEffect, useState } from 'react'
import InputText from '../components/InputText'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonSubmit from '../components/ButtonSubmit';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import { Toaster } from 'react-hot-toast';

const RegistrationForm = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const navigate = useNavigate();

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        if(password !== passwordConfirm){
            console.log("Not Same Password");
        }else {
            setLoading(true);
            let newUser = {
                email: email,
                password: password,
                lang: langUser
            }
            // Call l'api
            const response = await axios.post(apiUrl+'/registration', newUser);
            setLoading(false);
            navigate('/verify-email/0');
        }
    }

    const initialLanguage = ['en', 'fr'].includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0] : 'en';
    const [langUser, setLangUser] = useState(initialLanguage);

    
    useEffect(() => {
      i18n.changeLanguage(langUser);
    }, [langUser]);
  
    //Creating a method to change the language onChnage from select box
    const changeLanguageHandler = (e) => {
      const languageValue = e.target.value
      i18n.changeLanguage(languageValue);
      setLangUser(languageValue);
    }

    const formStyle = {
        margin: '20px 0'
    }
    const LoaderStyle = {
        textAlign : 'center',
        minHeight : '50vh',
        paddingTop: '20vh'
      }

    const style = {
        selectLanguage: {
            padding : '12px 20px',
            margin : '8px 0',
            fontSize: '17px',
            borderRadius: '15px',
            border : '2px solid #612bc3',
            width: '80%',
            color: '#000',
            backgroundColor: '#D4D4D4',
            textAlign: 'left',
            justifyContent: 'center',
        },
        label : {
            fontWeight: 'bold',
            fontSize: '20px',
            marginLeft:'8px'
          }
    }

    return (
        <>
            <Toaster />
            {loading ?
                <div style={LoaderStyle}>
                    <RotatingLines
                        strokeColor="#612bc3"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                        />
                    <h3>
                    {t('creation_account')} ...
                    </h3>
                </div> 
            : 
                <form onSubmit={handleSubmitForm} style={formStyle}>
                    <InputText label={t('label_registration_email')}
                        name={'registration_email-name'}
                        placeholder={t('placeholder_registration_email')}
                        onChange={setEmail}
                        value={email}
                        type={'email'}
                    />
                    <InputText label={t('label_registration_password')}
                        name={'registration_password-name'}
                        placeholder={t('placeholder_registration_password')}
                        onChange={setPassword}
                        value={password}
                        type={'password'}
                    />
                    <InputText label={t('label_registration_password_confirm')}
                        name={'registration_password_confirm-name'}
                        placeholder={t('placeholder_registration_password_confirm')}
                        onChange={setPasswordConfirm}
                        value={passwordConfirm}
                        type={'password'}
                    />
                    <div style={{textAlign: 'left', padding : '12px', width:'100%'}}>
                        <label htmlFor={"select-lang-user"} style={style.label}>{t('choose_lang_user')} : </label>
                        <select name="select-lang-user" style={style.selectLanguage} value={langUser} onChange={changeLanguageHandler}>
                            <option value="en" >English</option>
                            <option value="fr">Français</option>
                        </select>
                    </div>
                    <ButtonSubmit text={t('registration_button')} />
                </form>
            }
        </>
    )
}

export default RegistrationForm