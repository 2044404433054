import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// PAGES
import Goals from './pages/Goals';
import ErrorPage from './pages/ErrorPage';
import CreateGoal from "./pages/CreateGoal";
import UpdateGoal from "./pages/UpdateGoal";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import AuthProvider from "./Provider/AuthProvider";
import ProtectedRoute from "./Provider/ProtectedRoute";
import VerifyEmail from "./pages/VerifyEmail";
import ViewProvider from "./Provider/ViewProvider";
import Profile from "./pages/Profile";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordReset from "./pages/ForgotPasswordReset";

function App() {

  const MainDivStyle ={
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRoute element={<Goals />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "goals",
      element: <ProtectedRoute element={<Goals />} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "goals/create",
      element: <ProtectedRoute element={<CreateGoal />} />,
      errorElement: <ErrorPage />
    },
    {
      path: "goals/:id/update",
      element: <ProtectedRoute element={<UpdateGoal />} />,
      errorElement: <ErrorPage />
    },
    {
      path: "profile",
      element: <ProtectedRoute element={<Profile />} />,
      errorElement: <ErrorPage />
    },
    {
      path: "login",
      element: <Login />,
      errorElement: <ErrorPage />
    },
    {
      path: "registration",
      element: <Registration />,
      errorElement: <ErrorPage />
    },
    {
      path: "verify-email/:token",
      element: <VerifyEmail />,
      errorElement: <ErrorPage />
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
      errorElement: <ErrorPage />
    },
    {
      path: "reset-password/:token/:email",
      element: <ForgotPasswordReset />,
      errorElement: <ErrorPage />
    },
  ]);

  return (
    <div style={MainDivStyle}>
      <AuthProvider>
        <ViewProvider>
          <RouterProvider router={router} />
        </ViewProvider>
      </AuthProvider>
    </div>

  )
}

export default App;
