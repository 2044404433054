import React from 'react'
import { useTranslation } from "react-i18next";
import { Link, useParams } from 'react-router-dom';
import SkeletonPage from './SkeletonPage';
import ForgotPasswordFormReset from '../Forms/ForgotPasswordFormReset';

const ForgotPasswordReset = () => {
    const { t, i18n } = useTranslation();
    const { token, email } = useParams();

    const jsx = 
    <>
        <h1>{t('forgot_password_reset_page_title')}</h1>
        <ForgotPasswordFormReset tokenFromUrl={token} emailFromUrl={email}/>
        <Link className='linkStyle' to='/login'> {t('login_link')}</Link>
    </>
      
  return (
    <>
        <SkeletonPage content={jsx} />
    </>
  )
}

export default ForgotPasswordReset