import React from 'react'
import { useTranslation } from "react-i18next";
import { Link, useParams } from 'react-router-dom';
import SkeletonPage from './SkeletonPage';
import VerifyTokenForm from '../Forms/VerifyTokenForm';

const VerifyEmail = () => {
    const { t, i18n } = useTranslation();
    const { token } = useParams();

    const jsx = 
    <>
        <h1>{t('verify_page_title')}</h1>
        <VerifyTokenForm tokenFromUrl={token}/>
        <Link className='linkStyle' to='/registration'> {t('registration_link')} !</Link>
        <Link className='linkStyle' to='/login'> {t('login_link')}</Link>
    </>
      
  return (
    <>
        <SkeletonPage content={jsx} />
    </>
  )
}
export default VerifyEmail