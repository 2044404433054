import React, { useState } from 'react'
import InputText from '../components/InputText'
import { useTranslation } from 'react-i18next';
import ButtonSubmit from '../components/ButtonSubmit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { RotatingLines } from 'react-loader-spinner';

const ForgotPasswordForm = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  
  const handleSubmitForm = async (e) => {
    e.preventDefault();

    setLoading(true);
    // Call l'api
    try{
        const response = await axios.post(apiUrl+'/send-reset-password-email/'+email);
        setLoading(false);
        setApiCall(true);
    }catch(error){
        console.log("Something wrong happened: ". error);
        toast.error(t('notif_error'));
        setLoading(false);
        setApiCall(false);
    }
  }
  const LoaderStyle = {
    textAlign : 'center',
    minHeight : '50vh',
    paddingTop: '20vh'
  }
  return (
    <>
      <Toaster />
        {loading ?
          <div style={LoaderStyle}>
              <RotatingLines
                  strokeColor="#612bc3"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                  />
              <h3>
              {t('sending_email')} ...
              </h3>
          </div> 
  :  
        apiCall ? 
          <h3>
            {t('forgot_password_email_sent')}
          </h3>
          :
      <form onSubmit={handleSubmitForm}>
        <InputText  label={t('label_connexion_email')}
                    name={'connexion_email-name'}
                    placeholder={t('placeholder_connexion_email')}
                    onChange={setEmail}
                    value={email}
                    type={'email'}
        />

        <ButtonSubmit text={t('send_email_button')} />
      </form>
      }
    </>
    )
}

export default ForgotPasswordForm