import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import BurgerMenu from './BurgerMenu';
import ViewContext from '../Context/ViewContext';


const Header = ({auth, logout}) => {
    const { t, i18n } = useTranslation();
    const { isDarkMode } = useContext(ViewContext);

    const headerStyle = {
        backgroundImage: 'linear-gradient(to bottom, #612bc3, transparent)',
        display:'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding : '5px',
        color: '#202020'
      };

      const linkHeadserStyle = {
        textDecoration: 'none',
        maxWidth: '100px',
      }

      if (isDarkMode) {
        headerStyle.color = '#ced9e8';
      }
      
  return (
    <header style={headerStyle}>
      <Link style={linkHeadserStyle} to="/"><img id="logoHeaderImg" src="/img/logoapp.png"/></Link>
            {t('header_main_title')}
      <BurgerMenu auth={auth} logout={logout}/>
    </header>
  )
}

export default Header