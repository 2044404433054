import React, { useContext, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import ViewContext from '../Context/ViewContext';
const Footer = () => {
    const { t, i18n } = useTranslation();
    const { isDarkMode, setIsDarkMode } = useContext(ViewContext);

    const FooterStyle = {
      backgroundImage: 'linear-gradient(to top, #612bc3, transparent)',
      display:'flex',
        justifyContent: 'space-around',
        padding : '25px',
        textAlign: 'center',
        marginTop: 'auto',
        color: '#202020'
    };

    if (isDarkMode) {
      FooterStyle.color = '#ced9e8';
    }

  return (
    <footer style={FooterStyle}>
        <div>
        <h4>{t('footer_quotes')}</h4>
        <p>{t('footer_quotes_author')}</p>
        <br/><br/>
        <p>Bug / Question ? <a href="https://www.linkedin.com/in/yeun-le-floch/" target='_blank'>LinkedIn</a></p>
        </div>
    </footer>
  )
}

export default Footer