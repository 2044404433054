import React from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import SkeletonPage from './SkeletonPage';
import RegistrationForm from '../Forms/RegistrationForm';

const Registration = () => {
    const { t, i18n } = useTranslation();
    const jsx = 
    <>
        <h1>{t('registration_page_title')}</h1>
        <RegistrationForm />
        <Link className='linkStyle' to='/login'> {t('login_link')}</Link>
    </>
      
  return (
    <>
        <SkeletonPage content={jsx} />
    </>
  )
}

export default Registration