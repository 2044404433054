import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import InputText from './InputText';
import ButtonSubmit from './ButtonSubmit';

const UpdateAGoal = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [goalUpdate, setGoalUpdate] = useState({})
    const [name, setName] = useState(location.state.name)
    const [keyNbr, setKeyNbr] = useState(location.state.keyNumber)
    const [advancement, setAdvancement] = useState(location.state.advancement)
    const { id } = useParams();

    const handleSubmitForm = (e) => {
        e.preventDefault();
        // Call l'api 
        let newGoal = {
            id_user: '1',
            name: name,
            keyNbr : keyNbr,
            advancement : advancement
        }
        setGoalUpdate(newGoal);
      }
  return (
    <form onSubmit={handleSubmitForm}>

        <InputText label={t('label_goal_name')} 
                    name={'goal-name'} 
                    placeholder={t('placeholder_name_goal')} 
                    onChange={setName}
                    value={name}
                    type={'text'}
                    />
        <InputText label={t('label_goal_key_nbr')} 
                        name={'goal-key_nbr'} 
                        placeholder={t('placeholder_key_nbr_goal')} 
                        onChange={setKeyNbr}
                        value={keyNbr}
                        type={'number'}
                        />
        <InputText label={t('label_goal_advancement')} 
                    name={'goal-advancement'} 
                    placeholder={t('placeholder_name_advancement')} 
                    onChange={setAdvancement}
                    value={advancement}
                    type={'number'}
                    />
        <ButtonSubmit text= {t('edit_button')} />
    </form>
  )
}

export default UpdateAGoal