import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import SkeletonPage from './SkeletonPage';
import LoginForm from '../Forms/LoginForm';
import { toast } from 'react-hot-toast';

const Login = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const notify = (text, bgColor ,icon) => toast(text, {
      duration: 4000,
      position: 'top-center',
      style: {
          backgroundColor: bgColor
      },
      // Custom Icon
      icon: icon,
      // Change colors of success/error/loading icon
      iconTheme: {
        primary: '#000',
        secondary: '#fff',
      },
      // Aria
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });

    useEffect(()=>{
          if(location.state && location.state.notifSuccess){
            notify(t('notif_success_validate_email'), '#7CFC00', ':D');
            location.state.notifSuccess = false;
          }
    }, [])

    const jsx = 
    <>
        <h1>{t('login_page_title')}</h1>
        <LoginForm />
        <Link className='linkStyle' to='/forgot-password'> {t('forgot_password_link')}</Link>
        <Link className='linkStyle' to='/registration'> {t('registration_link')} !</Link>
        <Link className='linkStyle' to='/verify-email/0'> {t('verify_link')}</Link>
    </>
      
  return (
    <>
        <SkeletonPage content={jsx} />
    </>
  )
}

export default Login