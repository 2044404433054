import React, { useContext, useState } from 'react'
import InputText from '../components/InputText'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonSubmit from '../components/ButtonSubmit';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import { Toaster, toast } from 'react-hot-toast';
import AuthContext from '../Context/AuthContext';

const LoginForm = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { auth, login, logout } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmitForm = async (e) => {
        e.preventDefault();
    
        setLoading(true);
        let user = {
            username: email,
            password: password,
        }
        // Call l'api
        try{
            const response = await axios.post(apiUrl+'/login', user);
            login(response.data.token, response.data.userId);
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userId', response.data.userId);
            setLoading(false);
            navigate('/');
        }catch(error){
            console.log("Something wrong happened: ". error);
            toast.error(t('notif_error_login'));
            setLoading(false);
        }
    }
    

    const formStyle = {
        margin: '20px 0'
    }
    const LoaderStyle = {
        textAlign : 'center',
        minHeight : '50vh',
        paddingTop: '20vh'
      }

    return (
        <>
            <Toaster />
            {loading ?
                <div style={LoaderStyle}>
                    <RotatingLines
                        strokeColor="#612bc3"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                        />
                    <h3>
                    {t('connexion_account')} ...
                    </h3>
                </div> 
            : 
                <form onSubmit={handleSubmitForm} style={formStyle}>
                    <InputText label={t('label_connexion_email')}
                        name={'connexion_email-name'}
                        placeholder={t('placeholder_connexion_email')}
                        onChange={setEmail}
                        value={email}
                        type={'email'}
                    />
                    <InputText label={t('label_connexion_password')}
                        name={'connexion_password-name'}
                        placeholder={t('placeholder_connexion_password')}
                        onChange={setPassword}
                        value={password}
                        type={'password'}
                    />
                    <ButtonSubmit text={t('connexion_button')} />
                </form>
            }
        </>
    )
}

export default LoginForm