import React from 'react'
import { useTranslation } from 'react-i18next';

const Logout = ({auth, logout}) => {
    const { t, i18n } = useTranslation();
    if(auth.token == null) {
        return null;
    }
    else {
        return <button className='buttonHeaderFooter' onClick={logout}>{t('logout_button')}</button>
    }
}

export default Logout