import React from 'react'
import { useTranslation } from 'react-i18next';
import SkeletonPage from './SkeletonPage';
import UpdateAGoal from '../components/UpdateAGoal';

const UpdateGoal = () => {
    const { t, i18n } = useTranslation();
    const jsx =  
    <>
      <h3>{t('update_goals_title')}</h3>
      <UpdateAGoal />
      <p> {t('update_goal_explanation')}</p>
    
    </>
  return <SkeletonPage content={jsx} />
  
}

export default UpdateGoal