import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link} from 'react-router-dom';
import { Toaster} from 'react-hot-toast';
import { RotatingLines, ThreeDots } from 'react-loader-spinner';
import { FaCheck, FaPen, FaTimes, FaTrash } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import useGoals from '../Hooks/useGoals';
import GoalCard from './GoalCard';
import Modal from './Modal';




const GoalCardView = ({auth}) => {
    const { t, i18n } = useTranslation();
    const {
        goals,
        loading,
        tempGoal,
        editingId,
        updatingId,
        setTempGoal,
        startEditing,
        saveEditing,
        cancelEditing,
        deleteGoal,
        handleMouseOver,
        handleMouseOut,
        handleCardClick,
        handleCloseModal,
        isModalOpen,
        selectedCard,
        getAdvancement
    } = useGoals(auth, t);


//Styles    
    const divStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center' 

    }
    const LoaderStyle = {
        textAlign : 'center',
        minHeight : '50vh',
        paddingTop: '20vh'
      }


    return (
        <>
            <Link className='linkStyle' to='/goals/create'> + {t('create_goal_link')}</Link>
            <Toaster />
            {loading ?
                <div style={LoaderStyle}>
                    <RotatingLines
                        strokeColor="#612bc3"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                        />
                    <h3>
                    {t('loading_goals')}
                    </h3>
                </div> 
            : 
            <div style={divStyle}>
                {isModalOpen && (
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal} children={
                        <GoalCard goal={selectedCard} key={selectedCard.id} 
                        saveEditing={saveEditing}
                        cancelEditing={cancelEditing}
                        startEditing={startEditing}
                        deleteGoal={deleteGoal}
                        editingId={editingId}
                        updatingId={updatingId}
                        tempGoal={tempGoal}
                        setTempGoal={setTempGoal}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        handleCardClick={handleCardClick}
                        getAdvancement={getAdvancement}
                        handleCloseModal={handleCloseModal}
                        origin={"modal"}
                    />
                    }/>
                )}

            {goals.map(goal => (
                <GoalCard goal={goal} key={goal.id} 
                    saveEditing={saveEditing}
                    cancelEditing={cancelEditing}
                    startEditing={startEditing}
                    deleteGoal={deleteGoal}
                    editingId={editingId}
                    updatingId={updatingId}
                    tempGoal={tempGoal}
                    setTempGoal={setTempGoal}
                    handleMouseOver={handleMouseOver}
                    handleMouseOut={handleMouseOut}
                    handleCardClick={handleCardClick}
                    getAdvancement={getAdvancement}
                />
              ))}
            </div>
            }
            <p>{t('goals_table_explanation_advancement')}</p>
        </>
    )
}

export default GoalCardView