import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import './ChoiceHourDayCron.css';
import ButtonSubmit from '../ButtonSubmit';
import toast, { Toaster } from 'react-hot-toast';
import { RotatingLines } from 'react-loader-spinner';

const ChoiceHourDayCron = ({auth}) => {
    const { t, i18n } = useTranslation();
    const [time, setTime] = useState("20:00");
    const [sendMail, setSendMail] = useState(true);
    const [loading, setLoading] = useState(false);
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [selectedDays, setSelectedDays] = useState(days);    
    const [idNotif, setIdNotif] = useState(null);
    
    const apiUrl = process.env.REACT_APP_API_URL;
    const pad = (number) => (number < 10 ? `0${number}` : number);

    //recupere les données depuis l'api
    const fetchSchedule = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/users/${auth.userId}/notifications`,  {
          headers: {
              accept: 'application/ld+json',
              Authorization: 'Bearer '+auth.token
          }
      });
      
      setIdNotif(response.data.id);
      setSendMail(response.data.sendMail);
      
      const timeDate = new Date(response.data.Time);
      const timeHours = pad(timeDate.getUTCHours());
      const timeMinutes = pad(timeDate.getUTCMinutes());

      setTime(`${timeHours}:${timeMinutes}`);

      const daysFromResponse = Object.entries(response.data)
      .filter(([day, isSelected]) => isSelected)
      .map(([day, isSelected]) => day);
      setSelectedDays(daysFromResponse);

      } catch (err) {
        toast.error(t('notif_error'));
      } finally {
        setLoading(false);
      }
    };

    //appel la fonction fetchSchedule au chargement de la page
    useEffect(() => {
      fetchSchedule();
    }, []);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        // builder le retour avec Monday: true, Tuesday: false, etc.
        const selectedDaysObj = days.reduce((acc, day) => {
          acc[day] = selectedDays.includes(day);
          return acc;
        }
        , {});

        //il faut que j'envoie sans la clé selectedDaysObj mais directement les jours.
        const body = {
          Time: time,
          sendMail: sendMail,
          ...selectedDaysObj,
        };

        await axios.patch(`${apiUrl}/notifications/${idNotif}`, body , {
        headers: {
          accept: 'application/ld+json', 
          Authorization: 'Bearer '+auth.token,
          'Content-Type': 'application/merge-patch+json'
      }}
      );
      notify(t('notif_success_update_notif'), '#7CFC00', ':D');
    } catch (err) {
      toast.error(t('notif_error'));
    } finally {
        setLoading(false);
      }
    };
  
    const notify = (text, bgColor ,icon) => toast(text, {
      duration: 4000,
      position: 'top-center',
      style: {
          backgroundColor: bgColor
      },
      // Custom Icon
      icon: icon,
      // Change colors of success/error/loading icon
      iconTheme: {
        primary: '#000',
        secondary: '#fff',
      },
      // Aria
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });

    const handleCheckboxChange = (day) => {
      setSelectedDays((prev) =>
        prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
      );
    };

    const LoaderStyle = {
      textAlign : 'center',
      minHeight : '50vh',
      paddingTop: '20vh'
    }
  return (
    <>
     <Toaster />
            {loading ?
                <div style={LoaderStyle}>
                    <RotatingLines
                        strokeColor="#612bc3"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                        />
                </div> 
            : 
      <>
        <h3>{t('choice_hour_day_cron_page_title')}</h3>
        <div className="form-container">
      <form onSubmit={handleSubmit}>
        <fieldset>
          <legend>{t('choose_day')}</legend>
          {days.map((day) => (
            <label key={day}>
              <input
                type="checkbox"
                checked={selectedDays.includes(day)}
                onChange={() => handleCheckboxChange(day)}
              />
              {t(day)}
            </label>
          ))}
        </fieldset>

        <label htmlFor={"select-hour-notif"}>
          {t('select_time')}:
          </label>
           <select name="select-hour-notif" value={time} onChange={(e) => setTime(e.target.value)}>
                <option value="00:00" >00 : 00</option>
                <option value="01:00">01 : 00</option>
                <option value="02:00">02 : 00</option>
                <option value="03:00">03 : 00</option>
                <option value="04:00">04 : 00</option>
                <option value="05:00">05 : 00</option>
                <option value="06:00">06 : 00</option>
                <option value="07:00">07 : 00</option>
                <option value="08:00">08 : 00</option>
                <option value="09:00">09 : 00</option>
                <option value="10:00">10 : 00</option>
                <option value="11:00">11 : 00</option>
                <option value="12:00">12 : 00</option>
                <option value="13:00">13 : 00</option>
                <option value="14:00">14 : 00</option>
                <option value="15:00">15 : 00</option>
                <option value="16:00">16 : 00</option>
                <option value="17:00">17 : 00</option>
                <option value="18:00">18 : 00</option>
                <option value="19:00">19 : 00</option>
                <option value="20:00">20 : 00</option>
                <option value="21:00">21 : 00</option>
                <option value="22:00">22 : 00</option>
                <option value="23:00">23 : 00</option>
            </select>


        <label>
          <input
            type="checkbox"
            value={sendMail}
            checked={sendMail}
            onChange={() => setSendMail(!sendMail)}
            />
            {t('be_notified_by_email')}
        </label>


        


        <ButtonSubmit text= {t('save_button')} />
      </form>
        </div>
        <br/><br/>
        <p>{t('notif_explanation')}</p>
        </>
      }
    </>
  )
}

export default ChoiceHourDayCron