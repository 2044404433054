import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";
import SkeletonPage from './SkeletonPage';
import NewGoal from '../components/NewGoal';
import AuthContext from '../Context/AuthContext';

const CreateGoal = () => {
    const { t, i18n } = useTranslation();
    const { auth, login, logout } = useContext(AuthContext);

    const jsx =  
    <>
      <h3>{t('create_goals_title')}</h3>
        <NewGoal auth={auth}/>
      <p> {t('create_goal_explanation')}</p>
    
    </>
  return <SkeletonPage content={jsx} />
}

export default CreateGoal