import React, { useState } from 'react'
import ViewContext from '../Context/ViewContext';

const ViewProvider = ({children}) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    return (
        <ViewContext.Provider value={{ isDarkMode, setIsDarkMode }}>
            {children}
        </ViewContext.Provider>
    );
}

export default ViewProvider