import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import InputText from './InputText';
import ButtonSubmit from './ButtonSubmit';
import toast, { Toaster } from 'react-hot-toast';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import axios from 'axios';
import NewSubGoal from './NewSubGoal';

const NewGoal = ({auth}) => {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('')
    const [keyNbr, setKeyNbr] = useState('')
    const navigate = useNavigate();
    const [fakeLoader, setFakeLoader] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [subGoals, setSubGoals] = useState([]);
    const [showSubGoals, setShowSubGoals] = useState(false);

    const handleSubmitForm = async(e) => {
        e.preventDefault();
        setFakeLoader(true)
        // Call l'api 
        let newGoal = {
            name: name,
            keyNumber : parseInt(keyNbr),
            subGoals: subGoals
        }
        let headers = {
          headers: {
            accept: 'application/ld+json',
            Authorization: 'Bearer '+auth.token,
            'Content-Type': 'application/ld+json'
        }
        }
        try{
          const response = await axios.post(`${apiUrl}/users/${auth.userId}/goals`, newGoal, headers);
          setFakeLoader(false);
          navigate('/goals', {state: {notifSuccess : true}})
        }catch(error){
          console.log("Something wrong happened: ". error);
          toast.error(t('notif_error'));
          setFakeLoader(false);
      }

      }

    const formStyle={
        margin: '20px 0'
    }
    const LoaderStyle = {
      textAlign : 'center',
      minHeight : '50vh',
      paddingTop: '20vh'
    }
    const styles = {
      addSubGoalBtn: {
        width: '140px',
        padding: '10px',
        borderRadius: '15px',
        margin: 'auto auto 20px auto',
        backgroundColor: '#D3D3D3',
        cursor: 'pointer',
        color: '#000',
        fontSize: '15px',
        border: '2px solid #000',

      }
    }
  return (
    <>
        <Toaster />
        {fakeLoader ?
        <div style={LoaderStyle}>
          <RotatingLines
            strokeColor="#612bc3"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
            />
            <h3>
              {t('add_goal_loading')}
            </h3>
          </div>
        : 
        <>
          <form onSubmit={handleSubmitForm} style={formStyle}>
              <InputText label={t('label_goal_name')} 
                      name={'goal-name'} 
                      placeholder={t('placeholder_name_goal')} 
                      onChange={setName}
                      value={name}
                      type={'text'}
                      />
              <InputText label={t('label_goal_key_nbr')} 
                      name={'goal-key_nbr'} 
                      placeholder={t('placeholder_key_nbr_goal')} 
                      onChange={setKeyNbr}
                      value={keyNbr}
                      type={'number'}
                      />

        {showSubGoals ? 
            <NewSubGoal
                subGoals={subGoals} 
                setSubGoals={setSubGoals} 
                t={t}
            />
          : (
            <div style={styles.addSubGoalBtn} onClick={() => {
              setShowSubGoals(true);
              }
            }>
              {t('add_subgoal')}
            </div>
          )
          }

           <ButtonSubmit text= {t('create_goal_button')} />
          </form>
        </>
        }
    </>
  )
}

export default NewGoal