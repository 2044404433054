import React, { useEffect, useState } from 'react'
import InputText from '../components/InputText'
import { useTranslation } from 'react-i18next';
import ButtonSubmit from '../components/ButtonSubmit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { RotatingLines } from 'react-loader-spinner';

const ForgotPasswordFormReset = ({tokenFromUrl, emailFromUrl}) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  

  useEffect(()=>{
    if(tokenFromUrl && tokenFromUrl != 0){
        setToken(tokenFromUrl);
    }
    if(emailFromUrl && emailFromUrl != 0){
        setEmail(emailFromUrl);
    }
}, [tokenFromUrl, emailFromUrl])


  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    if(password !== passwordConfirm){
      console.log("Not Same Password");
      toast.error(t('not_same_password'));
      setLoading(false);
    } else {
    let data = {
        token: token,
        email: email,
        password: password,
      }
      // Call l'api
     try{
        const response = await axios.post(apiUrl+'/reset-password', data);
        setLoading(false);
        navigate('/login');
      }catch(error){
        console.log("Something wrong happened: ". error);
        toast.error(t('notif_error'));
        setLoading(false);
      }
    }
  }
  const LoaderStyle = {
    textAlign : 'center',
    minHeight : '50vh',
    paddingTop: '20vh'
  }
  return (
    <>
      <Toaster />
        {loading ?
          <div style={LoaderStyle}>
              <RotatingLines
                  strokeColor="#612bc3"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                  />
              <h3>
              {t('reset_password_loader')} ...
              </h3>
          </div> 
  :  

      <form onSubmit={handleSubmitForm}>
        <InputText label={t('label_verify_token')}
                   name={'verify_token-name'}
                   placeholder={t('placeholder_verify_token')}
                   onChange={setToken}
                   value={token}
                   type={'text'}
         />
        <InputText label={t('label_connexion_email')}
                   name={'connexion_email-name'}
                   placeholder={t('placeholder_connexion_email')}
                   onChange={setEmail}
                   value={email}
                   type={'email'}
        />
        <InputText label={t('label_registration_password')}
                   name={'registration_password-name'}
                   placeholder={t('placeholder_registration_password')}
                   onChange={setPassword}
                   value={password}
                   type={'password'}
        />
        <InputText label={t('label_registration_password_confirm')}
                   name={'registration_password_confirm-name'}
                   placeholder={t('placeholder_registration_password_confirm')}
                   onChange={setPasswordConfirm}
                   value={passwordConfirm}
                   type={'password'}
        />

        <ButtonSubmit text={t('reset_password_button')} />
      </form>
      }
    </>
    )
}

export default ForgotPasswordFormReset