import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const ProfileButton = ({auth}) => {
    const { t, i18n } = useTranslation();
    if(auth.token == null) {
        return null;
    }
  return (
    <Link className='buttonHeaderFooter' to='/profile'>
        {t('profile_button')}
    </Link>  )
}

export default ProfileButton