import { t } from 'i18next';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

const Modal = ({ isOpen, onClose, children}) => {
  const modalRef = useRef(null);
  const {t, i18n} = useTranslation();

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }
  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    modalContent: {
      zIndex: 10,
      width: '80%',
      height: '80%',
      backgroundColor: '#e9e8e2',
      color: '#202020',
      borderRadius: '8px',
      border: '1px solid #e0e0e0',
    },
  };

  const theme = document.documentElement.classList.contains('dark') ? 'dark' : 'light';
  if(theme === 'dark') {
    modalStyles.modalContent.backgroundColor = '#0D3D56';
    modalStyles.modalContent.color = '#fff';
    }

  return (

    <div style={modalStyles.overlay} className='modale' onClick={handleOutsideClick}>
        <div ref={modalRef} style={modalStyles.modalContent}>
            {children}
            <div style={modalStyles.button} >
            <FaTimes style={{color:'white', fontSize:'20px', marginTop:'15px', cursor: 'pointer'}} onClick={onClose}/>
            </div>
        </div>
    </div>

  );
}

export default Modal;
