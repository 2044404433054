import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaPen, FaTimes, FaTrash } from 'react-icons/fa';
import { RiCheckboxFill } from "react-icons/ri";
import { GiCrossMark } from "react-icons/gi";
import { RotatingLines, ThreeDots } from 'react-loader-spinner';
import { Tooltip } from 'react-tooltip';
import AuthContext from '../Context/AuthContext';
import ButtonSubmit from './ButtonSubmit';
import InputLight from './Input/InputLight';


const SubGoalsTable = ({goal}) => {
    const { t, i18n } = useTranslation();
    const [editingId, setEditingId] = useState(null);
    const [updatingId, setUpdatingId] = useState(null);
    const [tempSubGoal, setTempSubGoal] = useState({name: '', isRealized: false});
    const apiUrl = process.env.REACT_APP_API_URL;
    const { auth, login, logout } = useContext(AuthContext);
    const [subGoals, setSubGoals] = useState(goal.subGoals);
    const [loading, setLoading] = useState(false);
    const [newSubGoal, setNewSubGoal] = useState({name: '', isRealized: false});
    const [loaderSubGoal, setLoaderSubGoal] = useState(false);




    const notify = (text, bgColor ,icon) => toast(text, {
        duration: 4000,
        position: 'top-center',
        style: {
            backgroundColor: bgColor
        },
        // Custom Icon
        icon: icon,
        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });

      useEffect(()=>{
        const fetchSubGoals = async () => {
            try{
                setLoading(true);
                const response = await axios.get(`${apiUrl}/users/${auth.userId}/goals/${goal.id}/sub_goals`,
                {
                    headers: {
                        accept: 'application/ld+json',
                        Authorization: 'Bearer '+auth.token
                    }
                }
                );
                const subGoalsFromApi = response.data['hydra:member'];
                setSubGoals(subGoalsFromApi);
                setLoading(false);
            }catch(error){
                console.log("Something wrong happened: ". error);
                setLoading(false);
            }
            // if(location.state && location.state.notifSuccess){
            //     notify(t('notif_success_create_goal'), '#7CFC00', ':D');
            //     location.state.notifSuccess = false;
            // }
  
        }
        fetchSubGoals();
    }, [])

  //Edition des obj
  const startEditing = (subGoal) => {
      setEditingId(subGoal.id);
      setTempSubGoal({
        name: subGoal.name,
        isRealized: Boolean(subGoal.isRealized),
      })
  }
  const saveEditing = async (subGoalId) => {
      setUpdatingId(subGoalId);
      let newSubGoal = {
        name: tempSubGoal.name,
        isRealized: Boolean(tempSubGoal.isRealized),
      }
      try{
          const response = await axios.patch(`${apiUrl}/sub_goalss/${subGoalId}`, newSubGoal, 
              {
                  headers: {
                      "Authorization": 'Bearer '+auth.token,
                      "Content-Type": 'application/merge-patch+json'
                   }
              });
          notify(t('notif_success_update_subgoal'), '#7CFC00', ':D');
          if (response.status === 200) {
            const updatedSubGoalIndex = subGoals.findIndex(subGoal => subGoal.id === response.data.id);
            console.log("Found subGoal at index: ", updatedSubGoalIndex); // Just for debugging
        
            const updatedSubGoals = [...subGoals];
            updatedSubGoals[updatedSubGoalIndex] =  {...updatedSubGoals[updatedSubGoalIndex], ...newSubGoal}
            setSubGoals(updatedSubGoals);

        }
        
        }catch(error){
          toast.error('Une erreur est survenue');
      }
      setEditingId(null);
      setUpdatingId(null);
      setTempSubGoal({name: '', isRealized: false});
    }

  const cancelEditing = () => {
      setEditingId(null);
      setTempSubGoal({name: '', isRealized: false});
  }

  const deleteSubGoal = async (subGoalId) => {
      setUpdatingId(subGoalId);
      try{
          const response = await axios.delete(`${apiUrl}/sub_goals/${subGoalId}`, 
              {
                  headers: {
                      "Authorization": 'Bearer '+auth.token,
                      "Content-Type": ' application/ld+json'
                   }
              });
          notify(t('notif_success_delete_subgoal'), '#7CFC00', ':D');
          if(response.status == 204){
              const deleteSubGoalIndex = subGoals.findIndex(subGoal => subGoal.id === response.data.id);
              const updateSubGoals = [...subGoals];
              updateSubGoals.splice(deleteSubGoalIndex, 1);
              setSubGoals(updateSubGoals);
          }
        }catch(error){
          toast.error(t('notif_error'));
      }
      setUpdatingId(null);
  }

  const addSubGoal = async (subGoal) => {
    setLoaderSubGoal(true);
    let newSubGoal = {
      name: subGoal.name,
      isRealized: Boolean(false),
      goal: `/goals/${goal.id}`
    }
    try{
        const response = await axios.post(`${apiUrl}/sub_goalss`, newSubGoal, 
            {
                headers: {
                    "Authorization": 'Bearer '+auth.token,
                    "Content-Type": 'application/ld+json'
                 }
            });
        notify(t('notif_success_create_subgoal'), '#7CFC00', ':D');
        if (response.status === 201) {
            const theNewSubGoal =  {...newSubGoal, id:response.data.id};
            const updatedSubGoals = [...subGoals, theNewSubGoal];
            setSubGoals(updatedSubGoals);
      }
      }catch(error){
        console.log(error);
        toast.error('Une erreur est survenue');
    }
    setNewSubGoal({name: '', isRealized: false});
    setLoaderSubGoal(false);
  }

    const handleMouseOver = (e) => {
        e.target.style.cursor = 'pointer';
    };

    const handleMouseOut = (e) => {
        e.target.style.cursor = 'default';
    };

    //Styles    
    const divStyle = {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
    }
    const ulStyle = {
        textAlign: 'left',
    }
    const liStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
        marginTop: '3px'
    }
    const LoaderStyle = {
        textAlign : 'center',
        minHeight : '20vh',
        paddingTop: '10vh'
      }
  return (
    <>
    {loading ?
        <div style={LoaderStyle}>
            <RotatingLines
                strokeColor="#612bc3"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
                />
            <h3>
            {t('loading_subgoals')}
            </h3>
        </div> 
    : 
    <>
    <div style={divStyle}>
        <ul style={ulStyle}>
    {
        subGoals.map(subGoal => (
            <li className='li-sub-goals' style={liStyle} key={subGoal.id}>
                    {updatingId === subGoal.id ? (
                            <ThreeDots
                                height="20" 
                                width="100%" 
                                radius="9"
                                color="#612bc3" 
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                                />
                ) : (
                <>    
                {editingId === subGoal.id ? (
                    <input checked={tempSubGoal.isRealized} 
                    type='checkbox' 
                    onChange={(e) => setTempSubGoal({...tempSubGoal, isRealized: e.target.checked})}
                    />
                    ) : (
                        subGoal.isRealized ? 
                        <RiCheckboxFill style={{color:'green', fontSize:'22px', margin:'5px'}} /> 
                        :
                        <GiCrossMark style={{color:'red', fontSize:'21px', margin:'5px'}} />
                        )}

                 {subGoal.name} 

                 {editingId === subGoal.id ? (
                     <>
                            <FaCheck onMouseOver={handleMouseOver}
                                     onMouseOut={handleMouseOut}
                                     style={{color:'green', fontSize:'14px', marginLeft:'15px'}}
                                     onClick={() => saveEditing(subGoal.id)}
                                     className="save-subgoal-button"
                                     />
                            <FaTimes onMouseOver={handleMouseOver}
                                     onMouseOut={handleMouseOut}
                                     style={{color:'red', fontSize:'14px', margin:'5px'}}
                                     onClick={cancelEditing}
                                     className="cancel-subgoal-button" 
                                     />
                            <Tooltip anchorSelect=".save-subgoal-button" direction="top"> {t('save_button')} </Tooltip>
                            <Tooltip anchorSelect=".cancel-subgoal-button" direction="top"> {t('cancel_button')} </Tooltip>
                        </>
                    ) : (
                        
                        <>
                            <FaPen onMouseOver={handleMouseOver}
                                   onMouseOut={handleMouseOut}
                                   onClick={() => startEditing(subGoal)} 
                                   style={{color:'#FF7F50', fontSize:'14px', margin:'5px'}}
                                   className="edit-subgoal-button"
                                   />
                            <FaTrash onMouseOver={handleMouseOver}
                                     onMouseOut={handleMouseOut}
                                     onClick={() => deleteSubGoal(subGoal.id)} 
                                     style={{color:'red', fontSize:'14px',margin:'5px'}}
                                     className="delete-subgoal-button"
                                     />
                            <Tooltip anchorSelect=".delete-subgoal-button" direction="top"> {t('delete_button')} </Tooltip>
                            <Tooltip anchorSelect=".edit-subgoal-button" direction="top"> {t('edit_button')} </Tooltip>
                        </>
                    )}
                </>)} 
            </li>
            ))
        }
        </ul>
        </div>
        
        {loaderSubGoal ? (
            <div style={{textAlign:'center', display:'block'}}>
            <ThreeDots 
                height="20" 
                width="100%" 
                radius="9"
                color="#612bc3" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
            </div>
            ):(
                <div style={divStyle}>
                <InputLight name={'new_subgoal-name'} type="text" placeholder={t('placeholder_new_subgoal')} 
                onChange={(e) => setNewSubGoal({...tempSubGoal, name: e.target.value})} 
                value={newSubGoal.name} />
                <FaCheck onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={() => addSubGoal(newSubGoal)} 
                    style={{color:'#FF7F50', fontSize:'22px', margin:'8px'}}
                    className="add-subgoal-button"
                />
                <Tooltip anchorSelect=".add-subgoal-button" direction="top"> {t('add')} </Tooltip>
                </div>
                )}
                </>
            }
            </>
            )
        }
        
        export default SubGoalsTable