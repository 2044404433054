import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../Context/AuthContext';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import WaitingPage from '../pages/WaitingPage';

const ProtectedRoute = ({element}) => {
    const { auth, login, logout } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [isVerified, setIsVerified] = useState(false);

   const verifyToken = async () => {
      const tokenFromStorage = localStorage.getItem('token');
      const userIdFromStorage = localStorage.getItem('userId');

      if (tokenFromStorage != null || tokenFromStorage != undefined) {
         try {
            const response = await axios.post(apiUrl+'/verify-token', 
            {}, 
            {
               headers: {
                  accept: 'application/ld+json',
                  Authorization: 'Bearer '+tokenFromStorage
               }
            }
            );
            if(response.data.valid){
               login(tokenFromStorage, userIdFromStorage);
               setIsVerified(true);
            } else {
               logout();
               navigate('/login');
            }
         } catch (error) {
            console.error('Error verifying token:', error);
            logout();
            navigate('/login');
         }
      } else {
         setIsVerified(true); // set to true if there's no token in local storage
      }
   }

    useEffect(() => {
      verifyToken();
    }, []);

    // Only render the route if the token has been verified
    if (!isVerified) return <WaitingPage />;

    return auth.token ? element : <Navigate to="/login" />
}

export default ProtectedRoute;
