import React, {useContext, useState} from 'react'
import { useTranslation } from "react-i18next";
import ViewContext from '../Context/ViewContext';


const DarkLightMode = () => {
    const [darkMode, setDarkMode] = useState(true);
    const { t, i18n } = useTranslation();
    const { isDarkMode, setIsDarkMode } = useContext(ViewContext);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        setIsDarkMode(!isDarkMode);   
        darkMode ? document.documentElement.classList.add('dark')
        : document.documentElement.classList.remove('dark');  
    }

  return <button className='buttonHeaderFooter' onClick={toggleDarkMode}>{t('toggle_button_text')} </button>

}

export default DarkLightMode