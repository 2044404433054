import React from 'react'

const InputLight = ({type, name, placeholder, onChange, value , label}) => {
    const styles = {
        input : {
            border : '1px solid #612bc3',
            borderRadius: '15px',
            padding : '9px 12px',
            margin : '5px 0',
            justifyContent: 'center',
            fontSize: '14px',
            color: '#612bc3',
            backgroundColor: '#D4D4D4',
        },
        div : {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        label : {
            fontWeight: 'bold',
            marginRight: '10px'
        }
    }
    undefined === label ? label = '' : label = label + ' : ';
    
      return (
        <div style={styles.div}>
        <label style={styles.label} htmlFor={name}>{label}</label>
          <input type={type} 
                 placeholder={placeholder} 
                 name={name} 
                 style={styles.input}
                 onChange={onChange}
                 value={value}
                 required
          />
        </div>
        )
}

export default InputLight