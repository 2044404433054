import React, { useContext, useEffect, useState } from 'react'
import InputText from '../components/InputText'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonSubmit from '../components/ButtonSubmit';
import axios from 'axios';
import { RotatingLines } from 'react-loader-spinner';
import { Toaster, toast } from 'react-hot-toast';

const VerifyTokenForm = ({tokenFromUrl}) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(()=>{
        if(tokenFromUrl && tokenFromUrl != 0){
            setToken(tokenFromUrl);
        }
    }, [tokenFromUrl])

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        try{
            const response = await axios.post(apiUrl+'/verify-email/'+token);
            navigate('/login', {state: {notifSuccess : true}})
        }catch(error){
            console.log("Something wrong happened: ". error);
            toast.error(t('notif_error'));
            setLoading(false);
        }
    }
    
    const formStyle = {
        margin: '20px 0'
    }
    const LoaderStyle = {
        textAlign : 'center',
        minHeight : '50vh',
        paddingTop: '20vh'
      }

    return (
        <>
            <Toaster />
            {loading ?
                <div style={LoaderStyle}>
                    <RotatingLines
                        strokeColor="#612bc3"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                        />
                    <h3>
                    {t('verify_account')} ...
                    </h3>
                </div> 
            : 
                <form onSubmit={handleSubmitForm} style={formStyle}>
                    <InputText label={t('label_verify_token')}
                        name={'verify_token-name'}
                        placeholder={t('placeholder_verify_token')}
                        onChange={setToken}
                        value={token}
                        type={'text'}
                    />
                    <ButtonSubmit text={t('validate_button')} />
                </form>
            }
        </>
    )
}

export default VerifyTokenForm