import React, { useContext } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AuthContext from '../Context/AuthContext'


const SkeletonPage = ({content}) => {

    const container={
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '15px',
        textAlign: 'center'
      }
      const { auth, login, logout } = useContext(AuthContext);

  return (
    <>
        <Header auth={auth} logout={logout}/>
            <div style={container}>
                {content}
            </div>
        <Footer />
    </>
  )
}

export default SkeletonPage